import React from "react";
import classNames from "classnames";
import CustomButton from "../../Common/components/CustomButton";
import {_t} from "../../Common/components/InjectIntlContext";

export type bannerSlideProps = {
  info: {
    className?: string;
    backgroundImg?: string;
    title?: string;
    subtitle?: string;
    btnLabel?: string;
    linkTo?: string;
    onClick?: () => any;
  };
};

const BannerSlide: React.FC<bannerSlideProps> = ({ info }) => {
  const {
    className,
    backgroundImg,
    title,
    subtitle,
    btnLabel,
    linkTo,
    onClick,
  } = info;

  return (
    <div className={classNames(className, "banner-slide")}>
      <div className="container">
        <div className="banner-slide__image">
          <img src={backgroundImg} alt="banner_background" />
        </div>
        <div className="banner-slide__content">
          <div className="banner-slide__title">{title}</div>
          <div className="banner-slide__sub-title">{subtitle}</div>
          <a href={_t('dashboard_link_sign_up')} rel='noreferrer noopener' target="_blank" className="btn btn--second banner-slide__btn">
            {_t("get_started")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerSlide;
